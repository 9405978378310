import React from "react"
import Layout from '../components/Layout';

const Home = () => {
  return(
    <Layout>
        <h2 className="title">schotter & staub</h2>
        <p>
          Hier entsteht demnächst eine Seite!
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi optio natus, illum molestiae. Maiores architecto aut odio quis hic optio adipisci soluta, iste perferendis quasi eos est nulla repellendus voluptas aperiam natus accusantium voluptates harum quod quaeratdolorem aut voluptate fuga repellat</p>
    </Layout>
  );
}

export default Home;
